/* eslint-disable */
const btoa = require('btoa');
const { default: axios } = require("axios");

const getConfig = (currentPath) => {

    let tokenUrl
    let redirectUri
    let clientId
    let clientSecret

    if (process.env.REACT_APP_LOGIN_ENV === "dev" || process.env.REACT_APP_LOGIN_ENV === "uat") {
        tokenUrl = "https://gluu-preprod01-qaapac.amstack-amwayidv2-preprod.amwayglobal.com/oxauth/restv1/token"
    } else if (process.env.REACT_APP_LOGIN_ENV === "psup") {
        tokenUrl = "https://gluu-preprod01-perf.amstack-amwayidv2-preprod.amwayglobal.com/oxauth/restv1/token"
    } else if (process.env.REACT_APP_LOGIN_ENV === "prod") {
        tokenUrl = "https://gluu-prod01-prod.amstack-amwayidv2-prod.amwayglobal.com/oxauth/restv1/token"
    }

    if (currentPath === 'uat.healthscreening.amway.my' || currentPath === 'healthscreening.amway.my') {
        redirectUri = process.env.REACT_APP_LOGIN_ENV === "uat" ? "https://uat.healthscreening.amway.my/oauth/authorize/" : "https://healthscreening.amway.my/oauth/authorize/";
        clientId = process.env.REACT_APP_LOGIN_ENV === "uat" ? "0oa1hnormnlJymg5G0h8" : "0oaqtllpthLPxsYRQ1t7";
        clientSecret = process.env.REACT_APP_LOGIN_ENV === "uat" ? "-Xae4b20D8MLYPuNnST_VwCIk0UdnJAm8qOp6hpl" : "axD9AVAlFoDFbmRKBy-xPeu8ObP1f8s7bNBC37XI";
    
    }else if (currentPath === 'uat.healthscreening.amway.com.bn' || currentPath === 'healthscreening.amway.com.bn') {
        redirectUri = process.env.REACT_APP_LOGIN_ENV === "uat" ? "https://uat.healthscreening.amway.com.bn/oauth/authorize/" : "https://healthscreening.amway.com.bn/oauth/authorize/";
        clientId = process.env.REACT_APP_LOGIN_ENV === "uat" ? "0oa1iq2131aI2HFPF0h8" : "0oarbnab2oYFJot8U1t7";
        clientSecret = process.env.REACT_APP_LOGIN_ENV === "uat" ? "17IBlP1J8YtjEShCIP1xt-jpgDI0r5GhEIqbxydx" : "fx1aQB0Uz-6z7_16U13XLMX7ECxPOmLcXFMZ2206";

    } else if (currentPath === 'psup.healthscreening.amway.my') {
        redirectUri = "https://psup.healthscreening.amway.my/oauth/authorize/";
        clientId = "0oa1hnormnlJymg5G0h8";
        clientSecret = "-Xae4b20D8MLYPuNnST_VwCIk0UdnJAm8qOp6hpl";

    } else if (currentPath === 'psup.healthscreening.amway.com.bn') {
        redirectUri = "https://psup.healthscreening.amway.com.bn/oauth/authorize/";
        clientId = "0oa1iq2131aI2HFPF0h8";
        clientSecret = "17IBlP1J8YtjEShCIP1xt-jpgDI0r5GhEIqbxydx";

    } else if (currentPath === 'localhost') {
        redirectUri = "https://uat.healthscreening.amway.my/oauth/authorize/";
        clientId = "0oa1hnormnlJymg5G0h8";
        clientSecret = "-Xae4b20D8MLYPuNnST_VwCIk0UdnJAm8qOp6hpl";

    }

    return {tokenUrl,redirectUri,clientId,clientSecret}
}

const getClientSecretBasic = (client_id,client_secret) => {
    return btoa(`${client_id}:${client_secret}`);
};


// const toFormBody = (data) => {
// 	let formBody = [];
// 	for (const property in data) {
// 		formBody.push(`${encodeURIComponent(property)}=${encodeURIComponent(data[property])}`);
// 	}
// 	return formBody.join('&');
// };

const toFormBody = (data) => new URLSearchParams(data).toString();

export const requestToken = async (params) => {

    let hostname = params.hostname
    let {tokenUrl,redirectUri,clientId,clientSecret} = getConfig(hostname)

    let authorization_code = params?.code
    const data = {
        grant_type: 'authorization_code',
        redirect_uri: redirectUri,
        code: authorization_code
    };

    let header = {
        Authorization: `Basic ${getClientSecretBasic(clientId,clientSecret)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        }

    let result;

    try {
        const response = await fetch(tokenUrl, {
            method: "POST",
            headers: header,
            body: new URLSearchParams(data).toString(), // Convert data to form-encoded format
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const resData = await response.json(); // Parse JSON response
          console.log("Token Response:", resData);
          result = resData.refresh_token; // Extract the refresh token if needed
    } catch (error) {
        console.log("See request token error:", error.response?.data || error.message);
        result = error.response?.data || error.message; // Handle error response
    }

    return result; // Return the result

};

export const requestRefreshToken = async (params) => {

    let hostname = params.hostname
    let aboId = params.aboId
    let affCode = params.affCode

    let {tokenUrl,redirectUri,clientId,clientSecret} = getConfig(hostname)

    let refreshToken = params.refreshToken
    const data = {
        grant_type: 'authorization_code',
        redirect_uri: redirectUri,
        code: refreshToken,
    };

    let header = {
        Authorization: `Basic ${getClientSecretBasic(clientId,clientSecret)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        }

    let result
    try {
        result = await refreshTokenAPI(refreshToken,aboId,affCode,hostname)
        
        console.log("See refresh token data:", result);
        return result;
    }
    catch(error) {
        console.log("See refresh token error:", error.response?.data || error.message);
        result = error?.response?.data

        return result
    }

};

const refreshTokenAPI = async (refreshToken, aboId, affCode, hostname) => {

    let {tokenUrl,redirectUri,clientId,clientSecret} = getConfig(hostname)

    const data = {
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
        scope: 'openid profile abo abo_name accounts_details_profile offline_access account_profile bonus:all:read mdms:accounts:read'
        };


    let header = {
        Authorization: `Basic ${getClientSecretBasic(clientId,clientSecret)}`,
        'Content-Type': 'application/x-www-form-urlencoded',
        }
    let result;

    try {
        // const resp2 = await axios.post(
        //     `${tokenUrl}?salesPlanAff=${affCode}&aboNumber=${aboId}`,
        //     toFormBody(data),
        //     {
        //         headers: header,
        //     }
        // );

        // // Assign the response data to `result`
        // result = resp2?.data;
        const response = await fetch(`${tokenUrl}?salesPlanAff=${affCode}&aboNumber=${aboId}`, {
            method: "POST",
            headers: header,
            body: new URLSearchParams(data).toString(), // Convert data to form-encoded format
          });
      
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const resData = await response.json(); // Parse JSON response
          result = resData; // Extract the refresh token if needed
    } catch (error) {
        // Assign the error response data to `result`
        result = error?.response?.data;
    }

    // Return the result after the request completes
    return result;
};
