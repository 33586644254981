/* eslint-disable */
import React, { useState, useEffect } from 'react';
import './style.scss';
import { useDispatch, useSelector } from "react-redux";
import { fetchLayout } from "../../redux/reducers/index";

import { getAPIHost } from '../../utils/helper';
import PopupConfirm from '../../components/popupConfirm/popupConfirm';

import jwt from "jsonwebtoken";

import axios from 'axios';
import { Box, Radio, RadioGroup, FormControl, Typography, Paper } from '@mui/material';

import { requestToken, requestRefreshToken } from "../../controllers/authController"


import Cookies from 'universal-cookie';

import * as ls from '../../utils/loginSession';

import _ from 'lodash'

import { logoutSession } from '../../utils/login';

function AboPicker() {

  // from contentstack
  const [layoutCS, setLayoutCS] = useState({});

  const contentStackData = useSelector(state => state?.layoutState?.layout);
  const currentPath = window.location.hostname;
  const dispatch = useDispatch();

  const [selectedAbo, setSelectedAbo] = useState({});

  const [idToken] = useState(jwt.decode(localStorage.getItem('idToken')))
  
  const [accounts, setAccounts] = useState([]);

  const [refreshToken, setRefreshToken] = useState("")

  const [showProceedErrorPopup, setShowProceedErrorPopup] = useState(false);

  const [showLoginErrorPopup, setShowLoginErrorPopup] = useState(false);

  const [showNoAccPopup, setShowNoAccPopup] = useState(false);

  useEffect(() => {
    if (!contentStackData) {
      console.log("contentStackData", contentStackData);
      dispatch(fetchLayout())
    }
  }, []);

  useEffect(() => {
    if (contentStackData !== undefined) {
      setLayoutCS(contentStackData?.layout?.mp);
    }
  }, [contentStackData]);

  const handleProceed = async () => {
    // console.log("see selectedAbo",selectedAbo)

    if(!_.isEmpty(selectedAbo)){
      const apiUrl = getAPIHost();

      let data = {
        aboId: selectedAbo.abo,
        affCode: selectedAbo.sales_plan_aff,
        refreshToken: refreshToken,
        hostname : currentPath
      }

      try {
        // let res = await axios.post(`${apiUrl}/auth/requestRefreshToken`,
        // data)

        let res = await requestRefreshToken(data)
        console.log("requestRefreshToken res", res)

        if(res) {

          const cookies = new Cookies();

          //make sure access token is cleared before setting new 1, to avoid duplicate
          cookies.remove('accessToken');
          
          let exp = new Date().getTime() + ls.getLoginMaxIdle() * 60 * 1000;
          cookies.set('accessToken', res?.access_token || '', ls.cookieOption(exp));
          localStorage.setItem('idToken', res?.id_token);
          localStorage.setItem('authCode', res?.refresh_token);
        } else {
          setShowLoginErrorPopup(true)
        } 

      } catch (err) {
        setShowLoginErrorPopup(true)
        console.log("Refresh Token Error : ",err);
      }

      localStorage.setItem('loginFlag', 'true');
      
      window.location.href = '/home';
      
    } else {
      setShowProceedErrorPopup(true)
    }

  }

  useEffect(() => {
    const fetchData = async () => {
      // const apiUrl = getAPIHost();
      const validCountry = ['my', 'bn'];
  
      let data = {
        code: localStorage.getItem('authCode'),
        hostname : currentPath
      };
  
      try {
        let res = await requestToken(data)
  
        if (res) {
          console.log("requestToken res", res);
          setRefreshToken(res);

          console.log("idToken.accounts",idToken.accounts)
          const filteredAccounts = _.filter(idToken.accounts, account => {
            return account.status.toLowerCase() === "active" &&
                  validCountry.includes(account.country.toLowerCase()) &&
                  account.sales_plan_aff === "100" &&
                  ["amwaybusiness", "member"].includes(account.acct_type.toLowerCase()) &&
                  ["businessowner", "member"].includes(account.acct_subtype.toLowerCase());
            });
  
          console.log("filteredAccounts",filteredAccounts)
  
          setAccounts(filteredAccounts);
          if(filteredAccounts?.length <= 0) {
            setShowNoAccPopup(true)
          }
        } else {
          setShowLoginErrorPopup(true)
        } 
  
      } catch (err) {
        setShowLoginErrorPopup(true)
        console.log("Token Error:", err);
      }
    };
  
    fetchData();
  }, []);

  const closeProceedErrorPopup = async () => {
    setShowProceedErrorPopup(false)
  }

  const closeLoginErrorPopup = async () => {
    setShowLoginErrorPopup(false)
    logoutSession();
    logoutRedirect();
  }

  const closeNoAccPopup = async () => {
    setShowNoAccPopup(false)
    logoutSession();
    logoutRedirect();
  }

  const logoutRedirect = async () => {
    const currentPath = window.location.hostname;
    if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
      window.location.href = '/testPost';

    } else if (currentPath === 'uat.biz.amway.my' || currentPath === 'biz.amway.my' || currentPath === 'psup.biz.amway.my') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_MY}/`;

    } else if (currentPath === 'uat.biz.amway.sg' || currentPath === 'biz.amway.sg' || currentPath === 'psup.biz.amway.sg') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_SG}/`;

    } else if (currentPath === 'uat.biz.amway.com.bn' || currentPath === 'biz.amway.com.bn' || currentPath === 'psup.biz.amway.com.bn') {
      window.location.href = `${process.env.REACT_APP_AMWAYHOMEPAGE_URL_BN}/`;

    }
  }


  return (
    <div>
      <PopupConfirm
      showPopup={true}
      title={layoutCS?.profile_selection?.title || "Please select your profile"}
      okBtn={{
        text: layoutCS?.profile_selection?.proceed || "Proceed",
        action: handleProceed,
      }}
      cancelBtn={{
        action: logoutRedirect,
      }}

    >
      <div>{layoutCS?.profile_selection?.description || "You are linked to multiple accounts. Please choose the account you wish to operate with."}</div>
      <br />
      <FormControl component="fieldset" sx={{ width: '100%' }}>
        <RadioGroup
          aria-label="options"
          name="options"
          value={selectedAbo}
          sx={{ width: '100%' }}
        >
          {accounts.map((item, index) => (
            <Paper
              key={index}
              onClick={() => setSelectedAbo(item)}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                mb: 2,
                borderRadius: '12px',
                border: '2px solid',
                borderColor: selectedAbo === item ? '#3b5bb7' : '#e0e0e0',
                backgroundColor: selectedAbo === item ? '#f1f2ff' : '#fff',
                boxShadow: selectedAbo === item ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                cursor: 'pointer',
              }}
            >
              <Box>
                <Typography variant="subtitle1" fontWeight="bold" color="#3b5bb7">
                  {item.abo_name}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {item.abo}
                </Typography>
              </Box>
              <Radio value={item}
              sx={{
                color: selectedAbo === item ? '#3b5bb7' : '#e0e0e0',
                '&.Mui-checked': {
                  color: '#3b5bb7',
                },
              }}/>
            </Paper>
          ))}
        </RadioGroup>
      </FormControl>
    
    </PopupConfirm>
    <PopupConfirm
      showPopup={showProceedErrorPopup}
      title={layoutCS?.profile_selection?.title_before_proceed || "Please select a profile before proceed"}
      okBtn={{
        text: layoutCS?.profile_selection?.okBtn || "OK",
        action: closeProceedErrorPopup,
      }}
      hideCloseButton={true}

    >

    </PopupConfirm>

    <PopupConfirm
      showPopup={showLoginErrorPopup}
      title={layoutCS?.profile_selection?.login_error || "Login Error"}
      okBtn={{
        text: layoutCS?.profile_selection?.ok_button || "OK",
        action: closeLoginErrorPopup,
      }}
      hideCloseButton={true}
    >
    <div>{layoutCS?.profile_selection?.login_error_description || "Oops! We hit a snag trying to log you in. Please try logging in again."}</div>

    </PopupConfirm>

    <PopupConfirm
      showPopup={showNoAccPopup}
      title={layoutCS?.profile_selection?.no_acc_title || "No Account"}
      okBtn={{
        text: layoutCS?.profile_selection?.ok_button || "OK",
        action: closeNoAccPopup,
      }}
      hideCloseButton={true}
    >
    <div>{layoutCS?.profile_selection?.no_acc_description || "No ABO Account"}</div>

    </PopupConfirm>
    </div>
  );
  
}

export default AboPicker;
