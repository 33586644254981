import React from 'react';
import componentQueries from 'react-component-queries';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import './utils/axios-interceptor';

import { EmptyLayout, LayoutRoute, MainLayout } from './framework-layout';
import PageSpinner from './components/PageSpinner';
import Health from './modules/index';

import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import './styles/reduction.scss';

import TestPost from './modules/testPost';
import AboPicker from './modules/aboPicker'
import HCCPartnerLogin from './modules/BGHPartner/login/login';

// Import routes
const Routes_shared = require('./routes');

const App = (props) => {
  const { breakpoint } = props;
  // Combine all routes
  const aboApcRoutes = [...Routes_shared.aboApcRoutes];
  const cusCareRoutes = [...Routes_shared.cusCareRoutes];
  const hccPartnerRoutes = [...Routes_shared.hccPartnerRoutes];

  // module, to hold the page object
  window.message = {};
  window.redirect = {};
  window.enums = {};

  const aboApcDomain = ['uat.healthscreening.amway.my', 'psup.healthscreening.amway.my', 'healthscreening.amway.my', 'uat.healthscreening.amway.com.bn', 'psup.healthscreening.amway.com.bn', 'healthscreening.amway.com.bn'];
  const cusCareDomain = ['uat.cuscare.healthscreening.amway.my', 'psup.cuscare.healthscreening.amway.my', 'cuscare.healthscreening.amway.my', 'uat.cuscare.healthscreening.amway.com.bn', 'psup.cuscare.healthscreening.amway.com.bn', 'cuscare.healthscreening.amway.com.bn'];
  const hccPartnerDomain = ['uat.partner.healthscreening.amway.my', 'psup.partner.healthscreening.amway.my', 'partner.healthscreening.amway.my', 'uat.partner.healthscreening.amway.com.bn', 'psup.partner.healthscreening.amway.com.bn', 'partner.healthscreening.amway.com.bn'];

  let currentPath = window.location.hostname;
  let isAboApc = false;
  let isCusCare = false;
  let isHccPartner = false;
  let isLocalHost = false;

  if (aboApcDomain.includes(currentPath)) {
    isLocalHost = false;
    isAboApc = true;
  } else if (cusCareDomain.includes(currentPath)) {
    isLocalHost = false;
    isCusCare = true;
  } else if (hccPartnerDomain.includes(currentPath)) {
    isLocalHost = false;
    isHccPartner = true;
  } else if (currentPath === 'localhost' || currentPath === '172.0.0.1') {
    isLocalHost = true;
    isAboApc = true;
    // isCusCare = true;
    // isHccPartner = true;
  }

  const oktaAuth = new OktaAuth({
    issuer: process.env.REACT_APP_ISSUER,
    clientId: process.env.REACT_APP_CLIENT_ID,
    redirectUri: `https://${window.location.host}${process.env.REACT_APP_CALLBACK_PATH}`,
    //redirectUri: `http://${window.location.hostname}${process.env.REACT_APP_CALLBACK_PATH}`,
    //pkce: false,
    scopes: ['openid', 'profile', 'email'],
    postLogoutRedirectUri: 'https://amway.okta.com',
  });

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  // const onAuthResume = async () => {
  //   console.log("TT1234");
  //   history.push('/');
  // };

  return (
    <>
      {isAboApc && (
        <Switch>
          <LayoutRoute
            exact
            path="/testPost"
            layout={EmptyLayout}
            component={() => <TestPost />}
          />

          <LayoutRoute
            exact
            path="/health"
            layout={EmptyLayout}
            component={() => <Health />}
          />

          <LayoutRoute
            exact
            path="/profile"
            layout={EmptyLayout}
            component={() => <AboPicker />}
          />

          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={Routes_shared.aboApcLandingPage} />
              <Route path="/home" component={Routes_shared.aboApcDashboardPage} />

              {aboApcRoutes.map((c, i) => (
                <Route exact={c.exact} key={i} path={c.path} component={c.component} />
              ))}
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      )}

      {isCusCare && isLocalHost === false && (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
          <Switch>
            <Route path='/oauth/authorize' component={LoginCallback} />
            {/* <Route path="/oauth/authorize" render={(props) => <LoginCallback {...props} onAuthResume={onAuthResume} />} /> */}
            {/* <Route path="/oauth/authorize" component={() => onAuthResume} /> */}
            <LayoutRoute
              exact
              path="/health"
              layout={EmptyLayout}
              component={() => <Health />}
            />

            <MainLayout breakpoint={breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <SecureRoute exact path="/" component={Routes_shared.cusCareLandingPage} />
                <SecureRoute path="/home" component={Routes_shared.cusCareDashboardPage} />

                {cusCareRoutes.map((c, i) => (
                  <SecureRoute exact={c.exact} key={i} path={c.path} component={c.component} />
                ))}
              </React.Suspense>
            </MainLayout>
            <Redirect to="/" />
          </Switch>
        </Security>
      )}

      {isCusCare && isLocalHost === true && (
        <Switch>
          <LayoutRoute
            exact
            path="/testPost"
            layout={EmptyLayout}
            component={() => <TestPost />}
          />

          <LayoutRoute
            exact
            path="/health"
            layout={EmptyLayout}
            component={() => <Health />}
          />

          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={Routes_shared.cusCareLocalHostLandingPage} />
              <Route path="/home" component={Routes_shared.cusCareDashboardPage} />

              {cusCareRoutes.map((c, i) => (
                <Route exact={c.exact} key={i} path={c.path} component={c.component} />
              ))}
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      )}

      {isHccPartner && (
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={() => <HCCPartnerLogin />}
          />

          <LayoutRoute
            exact
            path="/health"
            layout={EmptyLayout}
            component={() => <Health />}
          />

          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={Routes_shared.hccPartnerLandingPage} />
              <Route path="/home" component={Routes_shared.hccPartnerHomePage} />

              {hccPartnerRoutes.map((c, i) => (
                <Route exact={c.exact} key={i} path={c.path} component={c.component} />
              ))}
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      )}
    </>
  );
}

const query = ({ width }) => {
  if (width <= 575) {
    return { breakpoint: 'xxs' };
  }

  if (576 <= width && width < 767) {
    return { breakpoint: 'xs' };
  }

  if (768 <= width && width < 991) {
    return { breakpoint: 'sm' };
  }

  if (992 <= width && width < 1199) {
    return { breakpoint: 'md' };
  }

  if (1200 <= width && width < 1920) {
    return { breakpoint: 'lg' };
  }

  return { breakpoint: 'xl' };
};

export default componentQueries(query)(App);
